import React, { useEffect, useState } from 'react'
import Container from '@material-ui/core/Container'
import ContentPage from '@src/templates/contentPage'
import StartPage from '@src/templates/startPage'

const pimcoreRest = async ({ endpoint, fetchOptions }) => {
	const host = process.env.GATSBY_PIMCORE_BASE_URL
	const version = process.env.GATSBY_PIMCORE_REST_VERSION

	const baseUrl = `${host}/rest/${version}`
	const url = `${baseUrl}/${endpoint}`

	const response = await fetch(url, fetchOptions)
	return response.json()
}

/**
 * This hook is only for CMS preview.
 */
const usePimcorePreview = () => {
	const [loadedPageContext, setLoadedPageContext] = useState(null)

	useEffect(() => {
		const queryParameters = new URL(window.location.href)
		const pagePath = queryParameters.searchParams.get('path')
		const documentId = queryParameters.searchParams.get('id')

		let pagesEndpointUrl = `pages${pagePath}?id=${documentId}`

		pimcoreRest({
			endpoint: pagesEndpointUrl,
			fetchOptions: {
				method: 'GET',
				credentials: 'include',
			},
		}).then((json) => setLoadedPageContext(json))
	}, [])

	return loadedPageContext
}

const CmsPreview = () => {
	let page = null
	const previewPageContext = usePimcorePreview()

	const typeOfPage = previewPageContext ? previewPageContext.type : null
	if (typeOfPage) {
		switch (typeOfPage) {
			// Handle category page.
			case '@Ateles\\RestBundle\\Controller\\ContentController':
				page = <ContentPage pageContext={previewPageContext} />
				break
			case '@Ateles\\RestBundle\\Controller\\StartPageController':
				page = <StartPage pageContext={previewPageContext} />
				break
			default:
			// Do nothing atm
		}
	}

	return (
		<>
			{!page && (
				<Container key='preview-container'>{/* TODO: Add loader */}</Container>
			)}
			{page}
		</>
	)
}

export default CmsPreview
